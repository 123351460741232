import React, {useState} from 'react'
import './Gallery.css';
// import CloseIcon from '@material-ui/icons/close';

import Img1 from '..//../assets/New/EmerArt (1).webp';
import Img2 from '..//../assets/New/Screenshot 2024-12-20 091151.png';
import Img3 from '..//../assets/New/Screenshot 2024-12-20 091240.png';
import Img4 from '..//../assets/New/WhatsApp Image 2024-12-20 at 09.13.14_7188116b.jpg';
import Img5 from '..//../assets/New/art (2).webp';
import Img6 from '..//../assets/New/custom (2).webp';
import Img7 from '..//../assets/New/dj4.webp';
import Img8 from '..//../assets/New/DSC_0928.webp';
import Img9 from '..//../assets/New/HAR_7457.webp';
import Img10 from '..//../assets/New/HAR_7459.webp';
import Img11 from '..//../assets/New/HAR_7506.webp';
import Img12 from '..//../assets/New/HAR_7507.webp';
import Img13 from '..//../assets/New/IMG_6530.webp';
import Img14 from '..//../assets/New/group.jpg';
import Img15 from '..//../assets/Images/Custom (16).webp';
import Img16 from '..//../assets/Images/Custom (7).webp';
import Img17 from '..//../assets/Images/art (3).webp';
import Img18 from '..//../assets/Images/cul_4.webp';
import Img19 from '..//../assets/Images/cul_5.webp';
import Img20 from '..//../assets/Images/image-1.jpg';
import Img21 from '..//../assets/Images/image-3.jpg';
import Img22 from '..//../assets/Images/image-4.jpg';
import Img23 from '..//../assets/Images/image-9.jpg';
import Img24 from '..//../assets/Images/dj1.jpg';
import Img25 from '..//../assets/Images/dj8.webp';

const Gallery = () => {

    let data =[
        {
           id: 1,
           imgSrc: Img1, 
        },
        {
           id: 2,
           imgSrc: Img2, 
        },
        {
           id: 3,
           imgSrc: Img3, 
        },
        {
           id: 4,
           imgSrc: Img4, 
        },
        {
           id: 5,
           imgSrc: Img5, 
        },
        {
           id: 6,
           imgSrc: Img6, 
        },
        {
           id: 7,
           imgSrc: Img7, 
        },
        {
           id: 8,
           imgSrc: Img8, 
        },
        {
           id: 9,
           imgSrc: Img9, 
        },
        {
           id: 10,
           imgSrc: Img10, 
        },
        {
           id: 11,
           imgSrc: Img11, 
        },
        {
           id: 12,
           imgSrc: Img12, 
        },
        {
           id: 13,
           imgSrc: Img13, 
        },
      //   {
         //   id: 14,
         //   imgSrc: Img14, 
      //   },
      //   {
      //      id: 15,
      //      imgSrc: Img15, 
      //   },
      //   {
      //      id: 16,
      //      imgSrc: Img16, 
      //   },
      //   {
      //      id: 17,
      //      imgSrc: Img17, 
      //   },
      //   {
      //      id: 18,
      //      imgSrc: Img18, 
      //   },
      //   {
      //      id: 19,
      //      imgSrc: Img19, 
      //   },
      //   {
      //      id: 20,
      //      imgSrc: Img20, 
      //   },
      //   {
      //      id: 21,
      //      imgSrc: Img21, 
      //   },
      //   {
      //      id: 22,
      //      imgSrc: Img22, 
      //   },
      //   {
      //      id: 23,
      //      imgSrc: Img23, 
      //   },
      //   {
      //      id: 24,
      //      imgSrc: Img24, 
      //   },
      //   {
      //      id: 25,
      //      imgSrc: Img25, 
      //   },

    ]

    // const [model, setModel] = useState(false);
    // const [tempimgSrc, setTempImgSrc] = useState('');

    // const getImg = (imgSrc) => {
    //     setTempImgSrc(imgSrc);
    //     setModel(true);
    // }
  return (
    <>
    {/* <div className={model? "model open": "model"}>
        <img src={tempimgSrc} alt="" />
         <CloseIcon onClick={() => setModel (false)}></CloseIcon> 
    </div>  */}

    <div className="header-gallery">
      <span className='stroke-text'>Gallery of</span>
      <span>Events</span>
      <span className='stroke-text'>Celebration</span>
    </div>
    
<div className="gallery">
    {data.map((item, index) => {
return(
    <div className="pics" key={index}>
        <img src={item.imgSrc} style={{width:'100%'}} alt="" />
    </div>
)
})}
</div>

</>
  )
}

export default Gallery