import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import {
//     createBrowserRouter,
//     RouterProvider,
//     Route,
//     Link,
//     useRoutes,
// } from "react-router-dom";

// const router= createBrowserRouter([
    // {
    //     path: '/',
    //     element:<App/>
    // },
    // {
    //     path: 'gallery',
    //     element:<Gallery/>
    // },

// ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals