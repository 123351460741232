import React, {useRef} from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef()
     
    const sendEmail = (e) => { 
        e.preventDefault();
    
        emailjs.sendForm('service_ad723ml', 'template_1mee9ib', form.current,
          '-tBXerktmW7ZevEP-')
          .then((result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            });
      };

  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
             <div>

                <span className='stroke-text'>Let's Create </span>
                <span>Something</span>
             </div>
             <div>
                <span>Unforgettable </span>
                <span className='stroke-text'>Together</span>
             </div>

        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder='Enter Your Email address'/>
                <button className='btn-j'>Get In Touch</button>
                {/* <input className='btn-j' type="submit" value="Send">Join Me</input> */}
            </form>
        </div>
    </div>
  )
}

export default Join