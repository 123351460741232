import React from 'react'
import './Programe.css';
import {programsData} from '../../data/programsData';
import RightArrow from '../../assets/rightArrow.png';
const Programe = () => {
  const handleWhatsAppClick = () => {
    // const phoneNumber = '+19294969494'; // Replace with the recipient's WhatsApp number (include country code, e.g., '15551234567')
    const message = encodeURIComponent('"Hello, I’m interested in booking an event with you. Could you please share the details and availability? Looking forward to your response. Thank you!"');
    const whatsappURL = `https://wa.me/${+19294969494}?text=${message}`;
    window.open(whatsappURL, '_blank');
};
  return (
    <div className="programs" id="programs">
     <div className="programs-header">
      <span className='stroke-text'>Explore Our</span>
      <span>Programs</span>
      <span className='stroke-text'>And Events</span>
      </div>

      <div className="programs-categories">
        {programsData.map((programs)=>(
          <div className='category'>
            {programs.image}
            <span>{programs.heading}</span>
            <span>{programs.details}</span>
            
            <div className="join-now">
        
          {/* <span>Book Now </span>
          <img src={RightArrow} alt="" /> */}
          <button className='btn'onClick={handleWhatsAppClick}>Book Now!</button>
        </div>       
        </div>
        ))}
        </div>
        </div>
)
}
export default Programe