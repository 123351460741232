import React from 'react'
import './Reason.css';
import Tick from '../../assets/tick.png'

const Reason = () => {
  return (
    <div className="Reason" id="reason">
        <div className='Left-r'>
          <div className="video-container">
      <video 
        className="responsive-video" 
        // controls 
        autoPlay 
        muted 
        loop
      >
        <source src="/Videos/IMG_1230.MP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
          
        </div>
        <div className="right-r">
             <span>Some Reasons</span>
             <div>
                <span className="stroke-text">Why </span>
                <span>Choose Us?</span>
             </div>
              
              <div className='detail-r'>
              <div >
                <img src={Tick} alt=""></img>
                <span>Tailored Events, Perfect Execution</span>
              </div>
              <div>
                <img src={Tick} alt="" />
                <span>Stunning Design & Décor</span>
              </div>
              <div>
                <img src={Tick} alt="" />
                <span>In-House Performers & Vendors</span></div>
              <div>
                <img src={Tick} alt="" />
                <span>Entertainment That Wows</span>
              </div>       
              <div>
                <img src={Tick} alt="" />
                <span>Seamless Planning to Execution</span>
              </div>       
              <div>
                <img src={Tick} alt="" />
                <span>Post-Event Perfection</span>
              </div>       
              <div>
                <img src={Tick} alt="" />
                <span>Global Reach, Local Expertise</span>
              </div>       
              <div>
                <img src={Tick} alt="" />
                <span>Your Vision, Our Passion</span>
              </div>       
             </div>
             {/* <span style={{
              color: "var(--gray)",
              fontweight: "normal",
            }}>OUR PARTNERS</span>

            <div className="partners">
              <img src="" alt="" />
              <img src="" alt="" />
              <img src="" alt="" />
            </div> */}
        </div>
    </div>
  )
};

export default Reason