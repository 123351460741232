import React from 'react'

import './Header.css';
import Logo from '../../assets/logo1.png';
import Bars from '../../assets/bars.png';
import { useState } from 'react';
import { Link } from 'react-scroll';


const Header = () => {

  const mobile= window.innerWidth<=1020 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false); 
   return (
    <div className="header">
    
    <img src={Logo} alt=""/>
    {/* // <h1>Tik Techno</h1> */}
{menuOpened === false && mobile ===true?(
  <div
  style={{
    backgroundColor:'var(--appColor)',
    padding: '0.5rem',
    borderRadius: '5px',
  }}
  onClick={() => setMenuOpened(true)}>

    <img src={Bars} alt="Bars" 
      style={{
        width: '1.5rem',
        height: '1.5rem',
      }}
     />
  </div>
): (<ul className='header-menu'>
<li>
  <Link
  onClick = {()=>setMenuOpened(false)}
  activeClass='active '
  to='home'
  span ={true}
  smooth={true}>
  Home
  </Link>
  </li>
<li><Link
  onClick = {()=>setMenuOpened(false)}
    to='programs'
  span ={true}
  smooth={true}>
  Services
  </Link></li>
  <li>
  <Link
  onClick = {()=>setMenuOpened(false)}
  to='reason'
  span ={true}
  smooth={true}>
  Why
  </Link>
  </li>
<li><Link
  onClick = {()=>setMenuOpened(false)}
  to='plans'
  span ={true}
  smooth={true}>
  Subscription
  </Link></li>
<li><Link
  onClick = {()=>setMenuOpened(false)}
  to='testimonials'
  span ={true}
  smooth={true}>
  Testimonials
  </Link></li>
<li><Link
  onClick = {()=>setMenuOpened(false)}
  to='join-us'
  span ={true}
  smooth={true}>
  Contact
  </Link></li>
</ul>
)}
    
    </div>
  )
}

export default Header