// import React from 'react'
// import './Footer.css'
// import Instagram from '..//../assets/instagram.png';


// const Footer = () => {
//   return (
//     <div className="Footer-container">
//         <hr />
//         <div className="footer">
//             {/* <img src={Instagram} alt="Instagram"/> */}
//             <a href="#"><img src={Instagram} alt="Instagram"/></a>
//             {/* <a href="#">Facebook</a> */}
//             {/* <a href="#">Twitter</a> */}
//         </div>
//         <div className="log-f">
//             {/* <img src={logo} alt="" /> */}
//             <span className='stroke-text'>Tik Techno</span>
//         </div>
//         <div className="copyright">
//             &copy; 2021 TikTechno. All rights reserved.
//         </div>
//     </div>
//   )
// }

// export default Footer{/* <img src={Instagram} alt="Instagram"/> */}
//             <a href="#"><img src={Instagram} alt="Instagram"/></a>
import React from "react";
import "./Footer.css";
// import Instagram from '..//..//assets/instagram.png';
import Logo from '../../assets/logo.png'
// import Instagram from "../assets/instagram.png";


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-about">
          {/* <h1>Tiktechno</h1> */}
          <img src={Logo} alt="" />
        </div>
        <div className="footer-links">
          <h4>About Us</h4>
          {/* <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">About Us</a></li>
            <li><a href="#">Why Us</a></li>
            <li><a href="#">Subscription</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul> */}
          <p>We create unforgettable experiences for your events, from enchanting weddings to electrifying DJ parties and vibrant social celebrations, making memories last forever.</p>
          <h4>Our Goals</h4>
          <p>At TikTechno, we believe that every event should be an extraordinary experience. With our dedicated team and attention to detail, we will transform your vision into reality. Contact us today to discuss your upcoming event and let us create a truly memorable celebration for you and your guests.</p>
        </div>
        <div className="footer-contact">
          <h4>Contact Us</h4>
          <p>Address: 611 South DuPont Highway,<br />Suite 102, Dover, DE 19901</p>
          <p>India Address: 1000, Sudama Nagar,<br /> Indore 452009</p>
          <p>Email: events@tiktechno.com</p>
          <p>Contact: +1(929) 496-9494</p>
          <p>Indian Contact: +91 9343256764</p>

          <div className="footer-socials">
            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> */}
            <a href="https://www.instagram.com/tiktechnonyc" target="_blank" rel="noopener noreferrer" >Instagram </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a>
            {/* <img src={Instagram} alt="Instagram"/> */}
{/* //             <a href="#"><img src={Instagram} alt="Instagram"/></a> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 Tiktechno. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
