import React, {useState} from 'react'
// import './App.css';
import Header from '../Header/Header.jsx';
import './Hero.css';
import HeroImage from '..//..//assets/carnival_doodle_seamless_pattern_2 [Converted].png';
import Lines from './calories.png';
import NumberCounter from 'number-counter';
import { Link } from 'react-scroll';
const Hero = () => {
  const handleWhatsAppClick = () => {
    // const phoneNumber = '+19294969494'; // Replace with the recipient's WhatsApp number (include country code, e.g., '15551234567')
    const message = encodeURIComponent('Hey There!');
    const whatsappURL = `https://wa.me/${+19294969494}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };
  const [menuOpened, setMenuOpened] = useState(false);
  // const setMenuOpened = useState(false); 
  return (
  
    <div className="hero" id='home'>
        <div className="left-h">
          <Header/>
          <div className="the-best-ad">
          <div></div>
          {/* <span>The Best Event Planner Where Every Event Becomes an Experience</span> */}
          {/* <span>Where Every Event Becomes an Experience</span> */}
          <span>Celebrations, made unforgettable!</span>
          </div>
        
        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>Dream. </span>
            <span>Design.</span>
          </div>
        <div>
          <span>Decor. </span>
          <span className='stroke-text'>Delight.</span>
        </div>
        <div>
        {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat sequi reiciendis ex, illum esse tenetur est repellat ipsa saepe similique numquam ad nemo, sint tempora nisi itaque fugit temporibus blanditiis.</span> */}
        <span>TIKTECHNO redefines celebration by blending style, innovation, and precision to create unforgettable events worldwide. With headquarters in the USA and India, we craft extraordinary experiences—from opulent weddings and high-energy DJ parties to sleek corporate affairs. Our bold, unique concepts leave a global imprint of elegance and energy.</span>
        </div>
        </div>
        {/* figure */}
        <div className="figures">
          <div>
            <span> <NumberCounter end={589} start={100} delay='4' preFix="+"/>
            </span>
            <span>Events</span>
          </div>
          <div>
            <span>
            <NumberCounter end={150} start={100} delay='4' preFix="+"/>
            </span>
            <span>Dj Party</span>
          </div>
          <div>
            <span>
            <NumberCounter end={350} start={100} delay='4' preFix="+"/>
            </span>
            <span>Session</span>
          </div>
        </div>
        {/* Hero Button */}
        <div className="hero-buttons">
          <button className="btn" > 
            <Link
              onClick = {()=>setMenuOpened(true)}
              to='plans'
              span ={true}
              smooth={true}>
              Subscription
              </Link>
          </button>
          
          <button className="btn">
            <Link
              onClick = {()=>setMenuOpened(true)}
              to='join-us'
              span ={true}
              smooth={true}>
              Contact Us
              </Link>
          </button>
        </div>
        </div>
      
        <div className="right-h">
          <button className="btn" onClick={handleWhatsAppClick}>Book Appointment</button>
    </div>
    {/* Hero-Image */}
    <img src={HeroImage} alt="" className='hero-image'/>

     {/* Events */}
     <div className="total-event">
      <img src={Lines} alt="" />
      <div>
      <span>Total Event</span>
      <span>1000k</span>
      </div>
     </div>
        </div>  
  )
}

export default Hero