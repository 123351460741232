import React from 'react';
import './App.css';
import Hero from '../src/components/Hero/Hero';
import Programe from './components/Programme/Programe';
import Reason from './/components/Reason/Reason';
import Plans from './components/Plans/Plans';
import Testimonials from './components/Testimonials/Testimonials';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer'
import Gallery from './components/Gallary/Gallery';


function App() {
  return (
   
    
    <div className="App">
      <Hero/>
      <Programe/>
      <Reason/>
      <Plans/>
      <Gallery/>
      <Testimonials/>
      <Join/> 
       <Footer/> 
    </div> 
  );
}

export default App;
