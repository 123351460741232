import image1 from "../assets/New/group.jpg";
import image3 from "../assets/New/DSC_0928.webp";
import image2 from "../assets/New/Prerna.jpg";

export const testimonialsData = [
  {
    image: image1,
    review: 'TIKTECHNO made our gender reveal party unforgettable! With stunning décor, seamless planning, and thoughtful execution, they amazed us and our guests. Their creativity and professionalism created a stress-free, magical event. Thank you for a perfect day!',
    name: 'Jhanvi',
    status : 'NYC'
  },
  {
    image: image2,
    review: 'TIKTECHNO made my baby shower unforgettable! With stunning décor, seamless planning, and attention to every detail, they brought my vision to life. The warm, joyful atmosphere created a magical, stress-free experience. Thank you, TIKTECHNO!',
    name: 'Prerna & Yug',
    status: 'New Jersey'
  },
  {
    image : image3,
    review:'TIKTECHNO turned our dream wedding at Nahargarh Palace into a royal celebration! From stunning décor to flawless execution, they captured our vision perfectly. Their professionalism made the process stress-free, leaving us with unforgettable memories. Thank you, TIKTECHNO!',
    name: 'Nilima',
    status: "India"
  }
];
