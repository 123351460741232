import React from 'react'
import {plansData} from '../../data/plansData';
import './Plans.css';
import WhiteTick from '..//..//assets/whiteTick.png';

const Plans = () => {
    const handleWhatsAppClick = () => {
        // const phoneNumber = '+19294969494'; // Replace with the recipient's WhatsApp number (include country code, e.g., '15551234567')
        const message = encodeURIComponent('"Hello, I’m interested in subscribing to your services. Could you please share the details and guide me through the process? Thank you!"');
        const whatsappURL = `https://wa.me/${+19294969494}?text=${message}`;
        window.open(whatsappURL, '_blank');
    };
  return (
    <div className="plans-container" id='plans'>
        <div className="programs-header" style={{gap:'2rem'}}>
            <span className='stroke-text'>Ready To </span>
            <span>Start Your JOurney</span>
            <span className='stroke-text'>With Us</span>
        </div>
      
        {/* pLans Card */}
        <div className="plans">
            
            {plansData.map((plan, i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>
                    <span>{plan.tagline}</span>

                    <div className="features">
                        {plan.features.map((feature,i)=>(
                            <div className="feature">
                                <img src= {WhiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>
{/* 
                    <div>
                        <span>See More Beefits</span>
                    </div> */}
                        <button className='btn'onClick={handleWhatsAppClick}>Subscribe Now!</button>
                    </div>
            ))}
        </div>
    </div>
  )
}

export default Plans